import axios from "axios";

export const ExternalApi = async (options) => {
  if (!options.config.headers) {
    options.config.headers = {
      "X-Requested-With": "XMLHttpRequest",
      "content-type": "application/json",
    };
  }
  if (!options.config.method) {
    options.config.method = "post";
  }

  try {
    const response = await axios(options.config);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
};
