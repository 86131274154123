import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { getLeadVolume } from "../Components/API/API";
import { useForm } from "react-hook-form";
import Input from "../Components/Forms/Input";
// import Select from "../Components/Forms/Select";

import instances from "../_data/instances.json";

function LeadVolume() {
  const [instanceLeadCount, setInstanceLeadCount] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function doGetLeadCount(instance, startDate, endDate, cookie) {
    var start = new Date( startDate).getTime()/1000;
    var end= ((new Date( endDate).getTime())/1000)-1;

    console.log(start, end)
    setInstanceLeadCount([]);
    var bar = new Promise((resolve, reject) => {
      if (instance) {
        try {
          getLeadVolume(instance, start, end, cookie)
            .then((res) => {
              setInstanceLeadCount((instanceLeadCount) => [
                ...instanceLeadCount,
                { instance: instance, ...res },
              ]);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log("ERROR", instance);
        }
      } else {
        instances.forEach(async function (instance) {
          try {
            getLeadVolume(instance, start, end, cookie)
              .then((res) => {
                setInstanceLeadCount((instanceLeadCount) => [
                  ...instanceLeadCount,
                  { instance: instance, ...res },
                ]);
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (err) {
            console.log("ERROR", instance);
          }
          resolve();
        });
      }
      resolve();
    });

    bar.then(() => {
      console.log("All done!");
    });
  }

  function handleFormSubmit(data) {
    doGetLeadCount(data.instance, data.start, data.end, data.cookie);
  }

  const downloadCSVFile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [document.getElementById("leadCountDataCSV").innerHTML],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = `leadvolume.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      <Helmet>
        <title>Lead Count / Databowl Tools</title>
      </Helmet>
      <main className="loading bg-primary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 align-content-center py-lg-5">
              <div className="p-4 my-5">
                <h1 className="text-center">Databowl Instance Volume</h1>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <Input
                    label="Instance Name (leave blank for all)"
                    register={register}
                    name="instance"
                    error={errors?.instance?.message}
                  />
                  <Input
                    label="Cookie"
                    register={register}
                    name="cookie"
                    error={errors?.cookie?.message}
                    validation={{
                      required: "This is required.",
                    }}
                  />
                  <Input
                    label="Start Date"
                    register={register}
                    name="start"
                    type="date"
                    error={errors?.start?.message}
                    />
                  <Input
                    label="End Date (for month checks put first day of following month)"
                    register={register}
                    name="end"
                    type="date"
                    error={errors?.end?.message}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-secondary"
                    value="Go"
                  />
                </form>

                {instanceLeadCount.length > 0 && (
                  <div>
                    <pre className="mt-5 p-5 bg-light" id="leadCountDataCSV">
                      {`InstanceName, total, pending, received, accepted, sale, flagged, rejected, clientRejected, quarantine`}

                      {"\n"}
                      {instanceLeadCount.length > 0 &&
                        instanceLeadCount.map(function (instance, i) {
                          return (
                            <React.Fragment key={`${instance}-${i}`}>
                              {instance?.pending || instance?.pending === 0 ? (
                                <>
                                  {`${instance.instance}, ${instance.total},  ${instance.pending},  ${instance.received},  ${instance.accepted},  ${instance.sale},  ${instance.flagged},  ${instance.rejected},  ${instance.clientRejected},  ${instance.quarantine} \n`}
                                </>
                              ) : (
                                <>{`[ERROR] ${instance.instance} \n`}</>
                              )}
                            </React.Fragment>
                          );
                        })}
                    </pre>
                    <button
                      className="btn btn-lg btn-white"
                      onClick={downloadCSVFile}
                    >
                      Download CSV
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default LeadVolume;
