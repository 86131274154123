import React, { useState } from "react"; //useState
import { Helmet } from "react-helmet-async";

function IPCheck() {
  const [result, setResult] = useState(false);
  const [ip, setIp] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    console.log(ip);
    getIP(ip);
  }

  function getIP(ip) {
    if (ip) {
      fetch(`https://functions.databowl.tech/API/IPFraudCheckFrontEnd?ip=${ip}`)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setResult(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div className="bg-primary min-vh-100">
      <Helmet>
        <title>IPCheck / Databowl Tools</title>
      </Helmet>
      <div className="py-lg-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h1 className="h2 text-center">
                Databowl IP Checker
              </h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card bg-darker-primary p-5 shadow">
                <p>
                  Determining lead fraud by checking IP addresses against
                  external denylists and registers as well as checking for known
                  spam traits such as VPN, proxy and tor.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="input-group float-right">
                    <input
                      type="text"
                      className="form-control bg-primary border-0 text-white input-sm"
                      placeholder="Enter the IP to check, eg: 80.2XXX.1XX.7X"
                      onChange={(e) => {
                        setIp(e.target.value);
                      }}
                    />
                    <button
                      type="submit"
                      className="btn border-0 btn-sm btn-secondary btn-lg"
                    >
                      Check
                    </button>
                  </div>
                </form>
                <p className="small mt-3">
                  Please note, results are not a guarantee, but are a guideline.
                  For best results please compare large volume of supplier
                  details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {result && (
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="col-lg-12 h4 py-3 px-5 bg-secondary">
                <span>IP:</span>
                <span className="text-darker-primary ml-2">{result.ip}</span>
              </div>
              <div className="card shadow bg-darker-primary p-5 mb-5">
                <div className="row">
                  <div className="col-lg-12 h4">
                    <span>Risk Score:</span>
                    <span className="text-secondary ml-2">
                      {result.risk_score.result}<span className="">%</span>
                    </span>
                  </div>
                  <div className="col-lg-12 small">
                    <p>Higher risk score means there is a higher risk</p>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-lg-12 h4">
                    <span>Anonymity:</span>
                  </div>
                  <div className="col-lg-4 mb-2">
                    <span className="h6">proxy</span>
                    <span className="small text-secondary ml-2 text-uppercase">
                      {result.anonymity.is_proxy ? "True" : "False"}
                    </span>
                  </div>
                  <div className="col-lg-4 mb-2">
                    <span className="h6">webproxy</span>
                    <span className="small text-secondary ml-2 text-uppercase">
                      {result.anonymity.is_webproxy ? "True" : "False"}
                    </span>
                  </div>
                  <div className="col-lg-4 mb-2">
                    <span className="h6">vpn</span>
                    <span className="small text-secondary ml-2 text-uppercase">
                      {result.anonymity.is_vpn ? "True" : "False"}
                    </span>
                  </div>
                  <div className="col-lg-4 mb-2">
                    <span className="h6">hosting</span>
                    <span className="small text-secondary ml-2 text-uppercase">
                      {result.anonymity.is_hosting ? "True" : "False"}
                    </span>
                  </div>
                  <div className="col-lg-4 mb-2">
                    <span className="h6">tor</span>
                    <span className="small text-secondary ml-2 text-uppercase">
                      {result.anonymity.is_tor ? "True" : "False"}
                    </span>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-12 h4">
                    <span>Information:</span>
                  </div>

                  <div className="col-lg-6">
                    <span className="text-capitalize">reverse dns</span>
                    <span className="text-secondary ml-2">
                      {result.information.reverse_dns}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">continent code</span>
                    <span className="text-secondary ml-2">
                      {result.information.continent_code}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">continent name</span>
                    <span className="text-secondary ml-2">
                      {result.information.continent_name}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">country code</span>
                    <span className="text-secondary ml-2">
                      {result.information.country_code}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">country name</span>
                    <span className="text-secondary ml-2">
                      {result.information.country_name}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">country currency</span>
                    <span className="text-secondary ml-2">
                      {result.information.country_currency}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">
                      country calling code
                    </span>
                    <span className="text-secondary ml-2">
                      {result.information.country_calling_code}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">region name</span>
                    <span className="text-secondary ml-2">
                      {result.information.region_name}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">city name</span>
                    <span className="text-secondary ml-2">
                      {result.information.city_name}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">latitude</span>
                    <span className="text-secondary ml-2">
                      {result.information.latitude}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">longitude</span>
                    <span className="text-secondary ml-2">
                      {result.information.longitude}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">ISP</span>
                    <span className="text-secondary ml-2">
                      {result.information.isp}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="text-capitalize">ASN</span>
                    <span className="text-secondary ml-2">
                      {result.information.asn}
                    </span>
                  </div>
                </div>

                <div className="row justify-content-between mt-5">
                  <div className="col-lg-12 h4 mb-1">
                    <span>Denylisted:</span>
                  </div>
                  {Object.keys(result.blacklists.engines).map((key) => (
                    <div className="col-lg-5 small">
                      <div className="row justify-content-between">
                        <div className="col-lg-8">
                          <a
                            href={result.blacklists.engines[key].reference}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {result.blacklists.engines[key].engine}
                          </a>
                        </div>
                        <div className="col-lg-4 text-uppercase text-secondary text-right">
                          {result.blacklists.engines[key].detected
                            ? "True"
                            : "False"}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default IPCheck;
