import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

// import reportWebVitals from "./reportWebVitals";

import App from "./App";

import "./scss/style.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="generator" content={`Databowl-Tools V.BETA.0.1A`} />
      <meta http-equiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#1f1a3c" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="" />

      {/* <meta name="image" content="https://www.databowl.com/static/0f20ed0692c28550b3d38f01b9cff2c7/social-default.jpg"/>
      <meta property="og:image" content="https://www.databowl.com/static/0f20ed0692c28550b3d38f01b9cff2c7/social-default.jpg"/>
      <meta name="twitter:image" content="https://www.databowl.com/static/0f20ed0692c28550b3d38f01b9cff2c7/social-default.jpg"/> */}
      {/* <meta property="fb:app_id" content=""/> */}
    </Helmet>

    <App />
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
//
