import React, { useState } from "react"; //useState
import { Helmet } from "react-helmet-async";

function IPCheck() {
  const [result, setResult] = useState(true);

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <div className="bg-primary min-vh-100">
      <Helmet>
        <title>Webhook Forwarder / Databowl Tools</title>
      </Helmet>
      <div className="py-lg-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h1 className="h2 text-center">Databowl Webhook Forwarder</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card bg-darker-primary p-5 shadow">
                <p>
                  Filter your lead data from our webhook tool to limit what you
                  expose to your partners.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="">
                    <label htmlFor="" className="h6">
                      Forwarding URL:
                    </label>
                    <input
                      type="text"
                      className="form-control bg-primary border-0 text-white input-sm"
                      placeholder="eg: https://webhook.co"
                      onChange={(e) => {}}
                    />
                  </div>
                  <div className="mt-4">
                    <label htmlFor="" className="h6 mb-3">
                      Fields:
                      <div className="small">Use the number from your fields in the integration doc. eg: f_2_firstname = 2</div>
                    </label>
                    <input
                      type="number"
                      className="form-control bg-primary border-0 text-white input-sm"
                      onChange={(e) => {}}
                    />
                  </div>

                  <div className="mt-4 text-right">
                    <button
                      type="submit"
                      className="btn btn-sm btn-secondary"
                      placeholder="eg: https://webhook.co"
                      onChange={(e) => {}}
                    >
                      Add Field
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {result && (
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="col-lg-12 p-5 bg-secondary">
                <input
                  className="bg-secondary border border-white w-100 p-4"
                  value="https://functions.databowl.tech/API/WebhookForwarder?filter=1&forward=https://www.webhookurl.com"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default IPCheck;
