import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { getReprocessLog } from "../Components/API/API";
import { useForm } from "react-hook-form";
import Input from "../Components/Forms/Input";
// import Select from "../Components/Forms/Select";

import instances from "../_data/instances.json";

function LeadReprocessLog() {
  const [instanceLeadCount, setInstanceLeadCount] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function doGetReprocessLog(instance, cookie) {
    setInstanceLeadCount([]);
    var bar = new Promise((resolve, reject) => {
      if (instance) {
        try {
          getReprocessLog(instance, cookie)
            .then((res) => {
              setInstanceLeadCount((instanceLeadCount) => [
                ...instanceLeadCount,
                { instance: instance, ...res },
              ]);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log("ERROR", instance);
        }
      } else {
        instances.forEach(async function (instance) {
          try {
            getReprocessLog(instance, cookie)
              .then((res) => {
                setInstanceLeadCount((instanceLeadCount) => [
                  ...instanceLeadCount,
                  { instance: instance, ...res },
                ]);
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (err) {
            console.log("ERROR", instance);
          }
          resolve();
        });
      }
      resolve();
    });

    bar.then(() => {
      console.log("All done!");
    });
  }

  function handleFormSubmit(data) {
    doGetReprocessLog(data.instance, data.cookie);
  }

  const downloadCSVFile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [document.getElementById("instance-reprocess-logs").innerHTML],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = `instance-reprocess-logs.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  let date = new Date();
  let today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  let month = `${date.getFullYear()}-${date.getMonth() + 1}`;

  let lastMonthDate = new Date();
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
  lastMonthDate.setDate(1);
  let lastMonth = `${lastMonthDate.getFullYear()}-${
    lastMonthDate.getMonth() + 1
  }`;

  let twoMonthDate = new Date();
  twoMonthDate.setMonth(twoMonthDate.getMonth() - 2);
  twoMonthDate.setDate(1);
  let twoMonth = `${twoMonthDate.getFullYear()}-${twoMonthDate.getMonth() + 1}`;

  return (
    <>
      <Helmet>
        <title>Lead Count / Databowl Tools</title>
      </Helmet>
      <main className="loading bg-primary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 align-content-center py-lg-5">
              <div className="p-4 my-5">
                <h1 className="text-center ,b-0">
                  Databowl Instance Reprocess Log
                </h1>
                <p className="text-center lead mb-5">
                  Shows todays reprocess log count
                </p>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <Input
                    label="Instance Name (leave blank for all)"
                    register={register}
                    name="instance"
                    error={errors?.instance?.message}
                  />
                  <Input
                    label="Your Cookie"
                    register={register}
                    name="cookie"
                    error={errors?.cookie?.message}
                    validation={{
                      required: "This is required.",
                    }}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-secondary"
                    value="Go"
                  />
                </form>

                {instanceLeadCount.length > 0 && (
                  <div>
                    <pre className="mt-5 p-5 bg-light" id="instance-reprocess-logs">
                      {`InstanceName, today ${today}, ${month}, ${lastMonth}, ${twoMonth}`}

                      {"\n"}
                      {instanceLeadCount.length > 0 &&
                        instanceLeadCount.map(function (instance, i) {
                          return (
                            <React.Fragment key={`${instance}-${i}`}>
                              {instance.instance && ( instance[today] || instance[month] || instance[lastMonth] || instance[twoMonth]) &&
                              <>
                                {`${instance.instance}, `+
                                `${instance[today] ? instance[today] : 0}, `+
                                `${instance[month] ? instance[month] : 0}, `+
                                `${instance[lastMonth] ? instance[lastMonth] : 0}, `+
                                `${instance[twoMonth] ? instance[twoMonth] : 0} \n`
                                }
                              </>}
                            </React.Fragment>
                          );
                        })}
                    </pre>
                    <button
                      className="btn btn-lg btn-white"
                      onClick={downloadCSVFile}
                    >
                      Download CSV
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default LeadReprocessLog;
