import React, { useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";

export const JSONParser = ({ initialCode, error, intro, title }) => {
  var [JSONResults, setJSONResults] = useState(0);
  const [code, setCode] = React.useState(initialCode);

  var obj_array = "";
  var intOrString = "";
  var tmp = "";

  function JSONStart(arr, depth) {
    if (!Array.isArray(arr)) {
      arr = [arr];
    }
    for (var i = 0; i < arr.length; i++) {
      JSONLoop(arr[i], depth, i, "");
    }
  }

  function JSONLoop(obj, depth, i, pre) {
    for (var key in obj) {
      var value = obj[key];
      if (typeof value === "object" && value !== null) {
        if (Array.isArray(value)) {
          obj_array = "=>";
        } else {
          obj_array = "->";
        }
        JSONLoop(value, depth + 1, 0, pre + key + obj_array);
      } else {
        if (Number.isInteger(value)) {
          intOrString = "Int";
        } else {
          intOrString = "String";
        }

        tmp +=
          "<tr><td className='font-weight-bold'>" +
          pre +
          key +
          "</td><td>" +
          value +
          " <span class='small text-monospace ml-1 code text-secondary'>(" +
          intOrString +
          ")</span></td></tr>";
      }
    }
    console.log(tmp);
    setJSONResults(tmp);
  }

  function JSONClickFunction() {
    var arr = code;

    if (isJson(arr)) {
      var arrJSON = JSON.parse(arr);
      JSONStart(arrJSON, 0);
    } else {
      alert(error);
    }
  }

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <div className="bg-primary min-vh-100">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h1 className="h2 text-center">{title}</h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card bg-darker-primary shadow p-4 p-lg-5">
              <p>{intro}</p>
              <CodeEditor
                value={code}
                language="js"
                placeholder="Please enter JS code."
                onChange={(evn) => setCode(evn.target.value)}
                padding={15}
                className="bg-light "
                style={{
                  fontSize: 12,
                  fontFamily:
                    "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                }}
              />
              <div className="mt-4">
                <button
                  className="btn btn-secondary btn-sm float-right"
                  onClick={function () {
                    JSONResults = "";
                    tmp = "";
                    JSONClickFunction();
                  }}
                >
                  Parse JSON
                </button>
              </div>
            </div>
          </div>
        </div>

        {JSONResults !== 0 && (
          <div className="row justify-content-center mt-5">
            <div className="col-lg-8 rounded">
              <table className="table table-dark shadow table-hover">
                <thead className="bg-secondary border-0">
                  <tr className="border-0">
                    <th className="h6">Input</th>
                    <th className="h6">Value Example</th>
                  </tr>
                </thead>
                <tbody
                  dangerouslySetInnerHTML={{ __html: JSONResults }}
                ></tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JSONParser;
