import React from "react";
import Icon from "../Icon";

function Input({
  error,
  helper,
  handleChange = null,
  label = "",
  name = "",
  placeholder = label,
  value = "",
  defaultValue = "",
  type = "text",
  autocomplete = "on",
  register,
  required = false,
  dark = true,
  validation,
  fullWidth = true,
}) {
  let theme = "form-control px-3 text-white bg-darker-primary";
  if (!dark) {
    theme = "form-control px-3 border-primary bg-light";
  }
  return (
    <div className="row mb-3 ">
      {label && (
        <label
          className={`${fullWidth ? "col-lg-12" : "col-lg-3"} align-self-top `}
        >
          <div className="font-weight-bold">{label}</div>
          {helper && <div className="small">{helper}</div>}
        </label>
      )}
      <div className={fullWidth ? `col-lg-12` : `col-lg-9`}>
        {type === "textarea" ? (
          <>
            {register ? (
              <textarea
                className={theme}
                placeholder={!placeholder ? label : placeholder}
                {...register(name ? name : label, { ...validation })}
              />
            ) : (
              <textarea className={theme} disabled value={value} type={type} />
            )}
          </>
        ) : (
          <>
            {register ? (
              <input
                className={theme}
                type={type}
                placeholder={!placeholder ? label : placeholder}
                {...register(name ? name : label, { ...validation })}
              />
            ) : (
              <input className={theme} disabled value={value} type={type} />
            )}
          </>
        )}
      </div>
      {error && (
        <div className="mt-2 col-12 small text-right opacity-80">
          <span className="px-2 py-1 bg-danger small rounded-sm">
            <Icon icon="xmark-circle" className="mr-1" />
            {error}
          </span>
        </div>
      )}
    </div>
  );
}
export default Input;
