import React from "react"; //, { useState, useEffect}
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "./Components/Layout";
import ScrollToTop from "./Components/ScrollToTop";

import Home from "./Pages/Home";
import IPCheck from "./Pages/IPCheck";
import NotFound from "./Pages/404";
import JSONParser from "./Pages/JSONParser";
import WebhookForwarder from "./Pages/WebhookForwarder";
import Billing from "./Pages/Billing";
import LeadCount from "./Pages/LeadCount";
import LeadVolume from "./Pages/LeadVolume";
import ReprocessLog from "./Pages/ReprocessLog";

function App() {
  
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/leadcount" element={<LeadCount />} />
            <Route path="/leadvolume" element={<LeadVolume />} />
            <Route path="/reprocessLog" element={<ReprocessLog />} />
            <Route path="/WebhookForwarder" element={<WebhookForwarder />} />
            <Route path="/JSONParser" element={<JSONParser />} />
            <Route path="/ipCheck" element={<IPCheck />} />
            <Route path="/ipCheck/:ip" element={<IPCheck />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
