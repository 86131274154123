import React from "react";
import Icon from "../Icon";

function Select({
  error,
  helper,
  handleChange = null,
  label = "",
  name = "",
  placeholder = label,
  value = "",
  defaultValue = "",
  type = "text",
  options = false,
  register,
  multiple = false,
  dark = true,
  validation,
  fullWidth = true,
}) {
  let theme =
    "custom-select custom-select-lg px-3 text-white bg-darker-primary";
  if (!dark) {
    theme = "custom-select custom-select-lg px-3 border-primary bg-light";
  }
  return (
    <div className="row mb-4 ">
      {label && (
        <label
          className={`${fullWidth ? "col-lg-12" : "col-lg-3"} align-self-top `}
        >
          <div className="font-weight-bold">{label}</div>
          {helper && <div className="small">{helper}</div>}
        </label>
      )}
      <div className={fullWidth ? `col-lg-12` : `col-lg-9`}>
        <select
          className={theme}
          type={type}
          multiple={multiple}
          defaultValue={!placeholder ? label : placeholder}
          {...register(name ? name : label, { ...validation })}
        >
          {options.map((option, index) => (
            <option
              key={`option-${index}`}
              value={option === defaultValue ? "" : option}
              // disabled={option === defaultValue ? true : false}
            >
              {option}
            </option>
          ))}
        </select>
      </div>
      {error && (
        <div className="mt-2 col-12 text-right small opacity-80">
          <span className="px-2 py-1 bg-danger small rounded-sm">
            <Icon icon="xmark-circle" className="mr-1" />
            {error}
          </span>
        </div>
      )}
    </div>
  );
}
export default Select;
