import { ExternalApi } from "./ExternalApi";

const apiServerUrl = process.env.REACT_APP_API_URL;

export const getBilling = async (instance, month, year, cookie) => {
  const config = {
    url: `${apiServerUrl}/billing`,
    data: { instance: instance, month: month, year: year, cookie: cookie },
  };
  try {
    return await ExternalApi({ config })
      .then((res) => res.data)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

export const getLeadCount = async (instance, cookie) => {
  const config = {
    url: `${apiServerUrl}/leadCount`,
    data: { instance: instance, cookie: cookie },
  };
  try {
    return await ExternalApi({ config })
      .then((res) => res.data)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

export const getLeadVolume = async (instance, start, end, cookie) => {
  const config = {
    url: `${apiServerUrl}/leadVolume`,
    data: { instance: instance, start: start, end: end, cookie: cookie },
  };
  try {
    return await ExternalApi({ config })
      .then((res) => res.data)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

export const getReprocessLog = async (instance, cookie) => {
  const config = {
    url: `${apiServerUrl}/reprocessLog`,
    data: { instance: instance, cookie: cookie },
  };
  try {
    return await ExternalApi({ config })
      .then((res) => res.data)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};
