import React from "react"; //useState
import { Helmet } from "react-helmet-async";
import JSONParser from "../Components/JSONParser";

function JSONParserPage() {
  return (
    <>
      <Helmet>
        <title>JSONParser / Databowl Tools</title>
      </Helmet>
      <JSONParser
        title={`Databowl JSON Parser Tool`}
        initialCode={`{`+
          `\n\t"cid": 10,`+
          `\n\t"sid": 1564,`+
          `\n\t"fields": [`+
          `\n\t\t{ "sub_id": "sub_10", "sub_class": "sub_child-of-9" },`+
          `\n\t\t{ "sub_id": "sub_11", "sub_class": "sub_child-of-91" }`+
          `\n\t]`+
          `\n}`}
          intro={`This tool will help you convert a supplied JSON to a set of instructions for Databowl Forwarder.`}
          error={`There is an error with the inputted JSON, please check it and try again.`}
      />
    </>
  );
}
export default JSONParserPage;
