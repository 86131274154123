import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { getLeadCount } from "../Components/API/API";
import { useForm } from "react-hook-form";
import Input from "../Components/Forms/Input";
// import Select from "../Components/Forms/Select";

function LeadCount() {
  const [instanceLeadCount, setInstanceLeadCount] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function doGetLeadCount(instance, cookie) {
    const instances = [
      "campaigns",
      "bluewave",
      "olfp",
      "britannia",
      "britannia",
      "convexmedia",
      "hellofreshde",
      "cohort",
      "conversant",
      "citizensdisability",
      "bulb",
      "audienceserv",
      "crsadvertising",
      "acquirely",
      "digidum",
      "contactlabs",
      "autoaffinitygroup",
      "aldaz",
      "adviceglobal",
      "gowie",
      "adminds",
      "sfw",
      "mcb",
      "dataphoria",
      "amvoc",
      "pdv",
      "azure",
      "eprospects",
      "aura",
      "digitalkhaos",
      "versiontwo",
      "leadcloud",
      "incallsrl",
      "ismart",
      "modstrom",
      "awin",
      "adverzy",
      "asthma",
      "comtell",
      "ago",
      "accelldigital",
      "embr",
      "rokkitpm",
      "searchprofessionals",
      "positiveresponse",
      "permissionmedia",
      "affiliatesperform",
      "interrog8",
      "dalmamedia",
      "quotesearch",
      "whitegoldmedia",
      "creationdigital",
      "citrusornge",
      "cfp",
      "css",
      "copious",
      "consultitech",
      "leadintelligence",
      "scwebsites",
      "adclickafrica",
      "swishmedia",
      "candc",
      "clickman",
      "avoice",
      "axiomlife",
      "believe",
      "leadsnavigator",
      "mab",
      "maplebrookwills",
      "wbm",
      "hellofresh",
      "magno",
      "tds",
      "firstutility",
      "demanddogs",
      "nextgenmedia",
      "olikaone",
      "steelinteractive",
      "MAB",
      "homeshield",
      "performanceleads",
      "inflecto",
      "eventdemo",
      "scottishpower",
      "ventica",
      "digitaladventures",
      "oallop",
      "reducero",
      "loudnewmedia",
      "hl",
      "futuraenergie",
      "firstlinefuneralcare",
      "vitola",
      "safestyle",
      "vesta",
      "nmg",
      "wyoh",
      "dfp",
      "hellofreshca",
      "hellofreshbenelux",
      "leadsfarmer",
      "smartmedalarm",
      "thedatasource",
      "leadtech",
      "secretescapes",
      "jam",
      "stenhojmedia",
      "themediaden",
      "intentifydemand",
      "pay4leads",
      "helloclicks",
      "jensten",
      "pivotalone",
      "retailcredit",
      "onelife",
      "masterpixel",
      "firsthouse",
      "marcellmedia",
      "nordicgaming",
      "wateraidau",
      "retailcredits",
      "webworker",
      "horizon",
      "palomadigital",
      "otelli",
      "sp",
      "winningdays",
      "rnid",
      "yourfirstclick",
      "datarush",
      "rezaeiconsulting",
      "hellofreshbe",
      "readgroup",
      "rpsconsultancy",
      "prism",
      "leasing",
      "threexmarketing",
      "differentgravydigital",
      "egentic",
      "hiscox",
      "insurancesupermarket",
      "grit",
      "inspiresales",
      "italk",
      "moneyresults",
      "dmleads",
      "ivaadvisor",
      "leadlocker",
      "switchonmedia",
      "wisebrands",
      "datarocks",
      "goldzebra",
      "hellofreshau",
      "perfectprospects",
      "uwclub",
      "ycl",
      "dgtl909",
      "excellentmedia",
      "freedom365",
      "leadtowin",
      "myoffers",
      "point",
      "sensoralarm",
      "traffictitans",
      "wearemedia",
      "economyenergy",
      "entire",
      "generationsun",
      "gerodigital",
      "golead",
      "lead2quote",
      "leaditonline",
      "solarcity",
      "swestfinancial",
      "zero5media",
    ];
    setInstanceLeadCount([]);
    var bar = new Promise((resolve, reject) => {
      if (instance) {
        try {
          getLeadCount(instance, cookie)
            .then((res) => {
              setInstanceLeadCount((instanceLeadCount) => [
                ...instanceLeadCount,
                { instance: instance, ...res },
              ]);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log("ERROR", instance);
        }
      } else {
        instances.forEach(async function (instance) {
          try {
            getLeadCount(instance, cookie)
              .then((res) => {
                setInstanceLeadCount((instanceLeadCount) => [
                  ...instanceLeadCount,
                  { instance: instance, ...res },
                ]);
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (err) {
            console.log("ERROR", instance);
          }
          resolve();
        });
      }
      resolve();
    });

    bar.then(() => {
      console.log("All done!");
    });
  }

  function handleFormSubmit(data) {
    doGetLeadCount(data.instance, data.cookie);
  }

  const downloadCSVFile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [document.getElementById("leadCountDataCSV").innerHTML],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = `leadcount.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  // function getMonthShortName(monthNumber) {
  //   const date = new Date();
  //   date.setMonth(monthNumber - 1);
  //   return date.toLocaleString("en-US", { month: "short" });
  // }

  var dateFormat = { year: "numeric", month: "short", day: "numeric" };

  var today = new Date().toLocaleDateString("en-GB", dateFormat);

  function leadDate(date) {
    date = new Date(date);
    return date.toLocaleDateString("en-GB", dateFormat);
  }

  return (
    <>
      <Helmet>
        <title>Lead Count / Databowl Tools</title>
      </Helmet>
      <main className="loading bg-primary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 align-content-center py-lg-5">
              <div className="p-4 my-5">
                <h1 className="text-center">Databowl Instance Lead Count</h1>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <Input
                    label="Instance Name (leave blank for all)"
                    register={register}
                    name="instance"
                    error={errors?.instance?.message}
                  />
                  <Input
                    label="Cookie"
                    register={register}
                    name="cookie"
                    error={errors?.cookie?.message}
                    validation={{
                      required: "This is required.",
                    }}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-secondary"
                    value="Go"
                  />
                </form>

                {instanceLeadCount.length > 0 && (
                  <div>
                    <pre className="mt-5 p-5 bg-light" id="leadCountDataCSV">
                      {`InstanceName, Lead Count Date,	Last Active Lead, Current Lead ID`}

                      {"\n"}
                      {instanceLeadCount.length > 0 &&
                        instanceLeadCount.map(function (instance, i) {
                          return (
                            <React.Fragment key={`${instance}-${i}`}>
                              {instance?._embedded?.lead[0]?.lead_id ? (
                                <>{`${instance.instance}, ${today}, ${leadDate(
                                  instance._embedded.lead[0].received_at
                                )}, ${
                                  instance._embedded.lead[0].lead_id
                                } \n`}</>
                              ) : (
                                <>{`[ERROR] ${instance.instance} \n`}</>
                              )}
                            </React.Fragment>
                          );
                        })}
                    </pre>
                    <button
                      className="btn btn-lg btn-white"
                      onClick={downloadCSVFile}
                    >
                      Download CSV
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default LeadCount;
