import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { getBilling } from "../Components/API/API";
import { useForm } from "react-hook-form";
import Input from "../Components/Forms/Input";
import Select from "../Components/Forms/Select";

import instances from "../_data/instances.json";

function Billing() {
  const [instanceBills, setInstanceBills] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const validationOptions = [
    "Email",
    "Validation: Email",
    "Validation: HLR",
    "Validation: UKAddressReplace",
    "Validation: UKAddress",
    "Validation: LLV",
    "Validation: SMS",
    "Validation: Inivio Phone",
    "Validation: TPS",
    "Validation: CTPS",
    "Validation: International lookup",
    "Validation: IP",
  ];

  function doGetBilling(month, year, instance, cookie) {

    setInstanceBills([]);
    var bar = new Promise((resolve, reject) => {
      if (instance) {
        try {
          getBilling(instance, month, year, cookie)
            .then((res) => {
              setInstanceBills((instanceBills) => [
                ...instanceBills,
                { instance: instance, ...res },
              ]);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log("ERROR", instance);
        }
      } else {
        instances.forEach(async function (instance) {
          try {
            getBilling(instance, month, year, cookie)
              .then((res) => {
                setInstanceBills((instanceBills) => [
                  ...instanceBills,
                  { instance: instance, ...res },
                ]);
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (err) {
            console.log("ERROR", instance);
          }
          resolve();
        });
      }
      resolve();
    });

    bar.then(() => {
      console.log("All done!");
    });
  }

  function findItem(array, item, instance) {
    try {
      let obj = array.find((o) => o.name === item);
      if (obj) {
        if (item === "Email") {
          return obj.totalCost;
        } else {
          return obj.quantity;
        }
      } else {
        return 0;
      }
    } catch (e) {
      console.log("[ERROR]:", instance, item);
      return false;
    }
  }

  function handleFormSubmit(data) {
    doGetBilling(data.month, data.year, data.instance, data.cookie);
  }

  const downloadCSVFile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [document.getElementById("billingDataCSV").innerHTML],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = `billing-${getValues("month")}-${getValues("year")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      <Helmet>
        <title>Billing / Databowl Tools</title>
      </Helmet>
      <main className="loading bg-primary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 align-content-center py-lg-5">
              <div className="p-4 my-5">
                <h1 className="text-center">Databowl Instance Billing</h1>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <Select
                    label="Month"
                    register={register}
                    name="month"
                    error={errors?.month?.message}
                    options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                    validation={{
                      required: "This is required.",
                    }}
                  />
                  <Select
                    label="Year"
                    register={register}
                    name="year"
                    error={errors?.year?.message}
                    options={[
                      2023, 2022, 2021, 2019, 2020, 2018, 2017, 2016, 2015, 2014,
                      2013, 2012, 2011, 2010,
                    ]}
                    validation={{
                      required: "This is required.",
                    }}
                  />
                  <Input
                    label="Instance Name (leave blank for all)"
                    register={register}
                    name="instance"
                    error={errors?.instance?.message}
                  />
                  <Input
                    label="Cookie"
                    register={register}
                    name="cookie"
                    error={errors?.cookie?.message}
                    validation={{
                      required: "This is required.",
                    }}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-secondary"
                    value="Go"
                  />
                </form>
                {/* <div id="billingData">
                  {instanceBills &&
                    instanceBills.map(function (instance, i) {
                      return (
                        <div className="mt-5">
                          <h2 className="mb-2 m4">{instance.instance}</h2>
                          <table className="table table-dark table-striped">
                            <tbody>
                              {instance.billing_item.map(function (item, i) {
                                return (
                                  <tr>
                                    <td>{item.name ? item.name : 0}</td>
                                    <td className="text-right">
                                      {item.quantity ? item.quantity : 0}
                                    </td>
                                    <td className="text-right">
                                      £ {item.unitCost ? item.unitCost : 0}
                                    </td>
                                    <td className="text-right">
                                      £ {item.totalCost ? item.totalCost : 0}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                </div> */}

                {instanceBills.length > 0 && (
                  <div>
                    <pre className="mt-5 p-5 bg-light" id="billingDataCSV">
                      {`InstanceName`}
                      {validationOptions.map(function (validation, vi) {
                        return (
                          <React.Fragment
                            key={`header-${validation}`}
                          >{`, ${validation}`}</React.Fragment>
                        );
                      })}
                      {"\n"}
                      {instanceBills.length > 0 &&
                        instanceBills.map(function (instance, i) {
                          return (
                            <React.Fragment key={`${instance}-${i}`}>
                              {instance.billing_item ? (
                                <>
                                  {instance.instance}
                                  {instance.billing_item &&
                                    validationOptions.map(function (
                                      validation,
                                      vi
                                    ) {
                                      return (
                                        <React.Fragment
                                          key={`${instance}-${i}-${validation}`}
                                        >
                                          {", "}
                                          {findItem(
                                            instance.billing_item,
                                            validation,
                                            instance.instance
                                          )}
                                        </React.Fragment>
                                      );
                                    })}
                                  {"\n"}
                                </>
                              ) : (
                                instance.instance && (
                                  <>{`\n[ERROR]: ${instance.instance} \n\n`}</>
                                )
                              )}
                            </React.Fragment>
                          );
                        })}
                    </pre>
                    <button
                      className="btn btn-lg btn-white"
                      onClick={downloadCSVFile}
                    >
                      Download CSV
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default Billing;
